// React
import { useRouter } from 'next/navigation';
import React from 'react';

// Upsell-kit
import upsell, { toCurrency } from '@monorepo/upsell-kit';

import CartCard from '../cart/CartCard';

// Component

// Props
interface Props {
	handleClose: () => void;
}

const CartModal = ({ handleClose }: Props) => {
	// Get the cart items array
	const { items } = upsell.cart.useItems();

	// calculate price directly
	const total = upsell.cart.usePrice();

	const router = useRouter();

	const handleCheckout = () => {
		router.push('/checkout/pickup');
		window.closeCart();
	};

	return (
		<div className="flex-bc col h-auto w-full">
			{/* Header */}

			{/* Main Container */}
			<div className="flex-sc col w-full flex-1 gap-4 overflow-y-auto px-6 py-6">
				{/* Map the item */}
				{items.map((item, i) => (
					<div className="w-full" key={i}>
						{i !== 0 && <div className="mb-4 h-px w-full bg-gray-200" />}
						<CartCard handleClose={handleClose} item={item} />
					</div>
				))}
			</div>

			{/* Checkout Button section */}
			<div className="flex-cc sticky bottom-0 h-20 w-full border-t border-solid border-t-slate-200 bg-white px-6 py-5">
				<button
					className=" flex-cc group w-full gap-2 rounded-full bg-theme-green py-4 text-sm font-medium uppercase  text-white hover:border hover:border-theme-green hover:bg-white hover:text-theme-green  lg:text-base"
					onClick={handleCheckout}
				>
					<p>Greiðsla</p>
					<div className=" flex h-1 w-1 rounded-full bg-gray-50 opacity-70 group-hover:bg-theme-green"></div>
					<p className=" opacity-70 group-hover:opacity-100">{`${toCurrency(total.price.final)}`}</p>{' '}
				</button>
			</div>
		</div>
	);
};

export default CartModal;

import clsx from 'clsx';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
	current_pathname: string;
	name: string;
	pathname: string;
	icon: StaticImport;
}

const NavItem = ({ name, pathname, icon, current_pathname }: Props) => (
	<li className="flex-cc">
		<Link href={pathname} className="flex-cc block flex-col">
			<Image
				src={icon}
				alt={name}
				className={clsx(current_pathname === pathname ? '' : 'opacity-60 grayscale')}
			/>
			<p
				className={clsx(
					'mt-1 text-[10px]',
					current_pathname === pathname
						? 'text-theme-green'
						: 'text-theme-text-secondary',
				)}
			>
				{name}
			</p>
		</Link>
	</li>
);

export default NavItem;

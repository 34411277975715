'use client';

import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

// SVGs
import MenuIcon from '../svgs/menu-icon.svg';
import ProfileIcon from '../svgs/profile-icon.svg';
import StoreIcon from '../svgs/store-icon.svg';
import SubwayIcon from '../svgs/subway.svg';
import CartIcon from './CartIcon';
import NavItem from './NavItem';

const LEFT_NAVS = [
	{
		name: 'Heim',
		pathname: '/',
		icon: SubwayIcon,
	},
	{
		name: 'Matseðill',
		pathname: '/menu',
		icon: MenuIcon,
	},
];

const RIGHT_NAVS = [
	{
		name: 'Staðir',
		pathname: '/store',
		icon: StoreIcon,
	},
	{
		name: 'Prófíll',
		pathname: '/profile',
		icon: ProfileIcon,
	},
];

type Props = {
	cartQuantity: number;
	handleToggleCart: () => void;
};

const excludeMobileNavPaths = ['/deals', '/products', '/upsell', '/checkout', '/checkout/pickup'];

const MobileNavigation = ({ cartQuantity, handleToggleCart }: Props) => {
	const pathname = usePathname();
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		// Check if the current path starts with any of the blacklisted paths
		const isPathExcluded = excludeMobileNavPaths.some((path) => pathname.startsWith(path));

		setShow(!isPathExcluded);
	}, [pathname]);

	return (
		<div
			className={clsx(
				'fixed bottom-0 left-0 right-0 z-[90] lg:hidden',
				show ? '' : '!hidden',
			)}
		>
			<div className="w-full px-3 pb-5">
				<nav className="flex-cc border-theme-border mx-auto h-[62px] w-full rounded-full border bg-white px-2 shadow-lg md:max-w-[355px]">
					<ul className="grid w-full grid-cols-5 gap-x-1">
						{LEFT_NAVS.map((item, i) => (
							<NavItem key={i} current_pathname={pathname} {...item} />
						))}

						{/* Cart */}
						<li className="flex-cc flex-col">
							<button onClick={() => handleToggleCart()} className="flex-cc relative">
								<CartIcon />
								{cartQuantity > 0 && (
									<div className="flex-cc absolute h-4 w-4 -translate-y-1.5 translate-x-1.5 rounded-full bg-theme-yellow text-xs leading-none text-white">
										{cartQuantity}
									</div>
								)}
							</button>
						</li>

						{RIGHT_NAVS.map((item, i) => (
							<NavItem key={i} current_pathname={pathname} {...item} />
						))}
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default MobileNavigation;

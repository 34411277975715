interface Props {
	className?: string;
}

const CartIcon = ({ className = '' }: Props) => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<circle cx="20" cy="20" r="20" fill="#008744" />
		<g clipPath="url(#clip0_578_8)">
			<path
				opacity="0.35"
				d="M29.47 13.645C29.093 13.235 28.557 13 27.999 13H12.275L12.165 12.169C12 10.933 10.935 10 9.687 10H9C8.447 10 8 10.447 8 11C8 11.553 8.447 12 9 12H9.687C9.937 12 10.15 12.187 10.183 12.434L11.72 23.962C11.95 25.694 13.441 27 15.188 27H25.785C27.591 27 29.123 25.591 29.273 23.791L29.993 15.165C30.038 14.609 29.848 14.055 29.47 13.645Z"
				fill="white"
			/>
			<path
				d="M16 29C17.1046 29 18 28.1046 18 27C18 25.8954 17.1046 25 16 25C14.8954 25 14 25.8954 14 27C14 28.1046 14.8954 29 16 29Z"
				fill="white"
			/>
			<path
				d="M25 29C26.1046 29 27 28.1046 27 27C27 25.8954 26.1046 25 25 25C23.8954 25 23 25.8954 23 27C23 28.1046 23.8954 29 25 29Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_578_8">
				<rect width="24" height="24" fill="white" transform="translate(8 8)" />
			</clipPath>
		</defs>
	</svg>
);

export default CartIcon;

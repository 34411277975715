type Props = {
	className?: string;
};

const Logo = ({ className = '' }: Props) => (
	<svg
		width="192"
		height="39"
		viewBox="0 0 192 39"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g clipPath="url(#clip0_1_58)">
			<path
				id="sub"
				d="M24.3054 34.2521C28.6904 33.3722 31.384 30.4812 31.384 25.7677C31.384 20.1114 27.7507 18.4773 21.1732 15.3349C18.0411 13.8266 17.2894 13.4495 17.2894 12.5068C17.2894 11.8783 17.7905 11.2498 18.7302 11.2498L29.8179 11.4384V4.08517C29.8179 4.08517 23.0525 3.95947 20.6721 3.95947C19.4819 3.95947 17.7279 3.95947 16.7256 4.08517C11.9021 4.71365 8.6447 7.91888 8.6447 12.4439C8.6447 18.3516 12.8418 20.1742 18.1664 22.6881C21.4865 24.2593 22.6767 24.6992 22.6767 25.8934C22.6767 26.5847 22.1755 27.2132 21.1106 27.2132L9.33377 27.1503V23.128L0 30.7326L9.27113 38.4001V34.3778L19.7951 34.4406C21.1106 34.5035 23.1152 34.4406 24.3054 34.2521ZM73.8558 27.7788H69.2829V21.9968H73.8558C75.5472 21.9968 76.3615 23.0652 76.3615 24.8878C76.3615 26.5847 75.5472 27.7788 73.8558 27.7788ZM75.6724 13.3866C75.6724 14.9578 74.9834 16.1519 73.4173 16.1519H69.2829V10.6842H73.4173C74.9207 10.6842 75.6724 11.7526 75.6724 13.3866ZM75.3592 34.5663C81.3729 34.5663 85.3821 31.2983 85.3821 25.2649C85.3821 22.0596 83.816 19.86 81.185 18.4145C83.4401 16.9061 84.5677 14.8321 84.5677 12.3811C84.5677 7.66749 81.2476 4.02232 74.8581 4.02232H60.4503V34.5663H75.3592ZM45.2907 35.1948C36.9592 35.1948 33.0127 30.6698 33.0127 25.8305V4.02232H41.908V25.1392C41.908 26.5218 43.0356 27.5902 45.2907 27.5902C47.5458 27.5902 48.6734 26.459 48.6734 25.1392V4.02232H57.6313V25.8305C57.6313 30.6698 53.1837 35.1948 45.2907 35.1948Z"
				fill="#FBBB00"
			/>
			<path
				id="way"
				d="M176.089 32.6809C176.214 32.6809 176.277 32.7437 176.277 32.8065C176.277 32.8694 176.214 32.9322 176.089 32.9322H175.838V32.6809H176.089ZM175.4 32.3038V33.8121H175.838V33.3093L176.214 33.8121H176.715L176.277 33.3093C176.527 33.2465 176.715 33.0579 176.715 32.8694C176.715 32.5552 176.527 32.3666 176.089 32.3666H175.4V32.3038ZM176.026 34.3149C175.337 34.3149 174.773 33.7493 174.773 33.1208C174.773 32.4295 175.337 31.8638 176.026 31.8638C176.653 31.8638 177.216 32.4295 177.216 33.1208C177.279 33.7493 176.715 34.3149 176.026 34.3149ZM176.026 31.4239C175.087 31.4239 174.335 32.1781 174.335 33.0579C174.335 33.9378 175.087 34.692 176.026 34.692C176.966 34.692 177.718 33.9378 177.718 33.0579C177.718 32.1781 176.966 31.4239 176.026 31.4239ZM142.512 18.7286H135.81V13.2609C135.81 11.8154 136.812 10.747 139.192 10.747C141.573 10.747 142.575 11.8154 142.575 13.2609L142.512 18.7286ZM135.81 34.5663V25.7047H142.512V34.5663H151.408V12.7581C151.408 7.2275 146.835 3.14239 139.192 3.14239C131.425 3.14239 126.977 7.29034 126.977 12.7581V34.5663H135.81ZM113.196 35.0691C120.65 35.0691 124.158 30.544 124.158 25.5791V4.02226H115.263V25.3277C115.263 26.2704 114.762 27.2759 112.757 27.2759C110.752 27.2759 110.251 26.2075 110.251 25.3277V4.02226H101.356V25.3277C101.356 26.2704 100.855 27.2759 98.7877 27.2759C96.7831 27.2759 96.282 26.2075 96.282 25.3277V4.02226H87.3867V25.5791C87.3867 30.544 90.6441 35.0691 98.5998 35.0691C101.607 35.0691 103.987 34.1264 105.741 32.6809C107.558 34.1264 110.001 35.0691 113.196 35.0691ZM162.433 16.0262C162.433 17.1574 163.184 18.5401 165.878 18.5401C168.572 18.5401 169.323 17.2203 169.323 16.0262V11.1869C169.323 6.85041 171.516 3.83372 176.715 3.83372H182.854V0L192.125 7.66743L182.854 15.3349V11.5011H178.908C178.407 11.5011 178.156 11.7525 178.156 12.1925V16.9061C178.156 21.1797 175.4 24.3849 170.451 25.7047V34.5034H161.43V25.7047C156.544 24.3849 153.663 21.1169 153.663 16.9061V4.02226H162.495L162.433 16.0262Z"
				className="fill-white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_58">
				<rect width="192" height="38.4" className="fill-white" />
			</clipPath>
		</defs>
	</svg>
);

export default Logo;

import { asText, isFilled } from '@prismicio/helpers';
import { PrismicNextLink } from '@prismicio/next';
import Image from 'next/image';
import React, { useEffect } from 'react';

import { useLayout } from '../../../contexts';
import { TopBannerDocument } from '../../../prismicio-types';

type TopBannerProps = {
	topBanner: TopBannerDocument;
};

function TopBanner({ topBanner }: TopBannerProps) {
	const { setTopBannerActive } = useLayout();

	useEffect(() => {
		if (!topBanner) {
			return;
		}
		setTopBannerActive(topBanner.data.active);
	}, [topBanner]);

	return (
		<div className="relative w-full overflow-hidden bg-theme-yellow">
			<div className="absolute right-0 h-[96px] w-[545px] bg-theme-green md:h-[64px] -lg:hidden">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="327"
					height="64"
					fill="none"
					viewBox="0 0 327 64"
					className="relative right-1/3"
				>
					<path
						fill="#FBBB00"
						d="M268.292 78.923L240.9 32.357 29.774 156.55.392 106.602 211.518-17.591l-27.392-46.566L326.24-51.46 268.292 78.924z"
					></path>
				</svg>
			</div>
			<div className="flex-sc col md:flex-bc relative z-10 w-full px-3 py-3 lg:container md:h-[64px] md:flex-row md:gap-2 md:py-0">
				<div className="flex-ss md:flex-sc flex-1 gap-3">
					<div className="relative mt-1 h-8 w-8 overflow-hidden rounded-full md:mt-0">
						<Image
							src="/assets/icons/subway-circle.png"
							alt="flag"
							fill
							objectFit="cover"
						/>
					</div>
					<div className="flex-1">
						<p className="text-sm">{asText(topBanner.data.paragraph)}</p>
						{isFilled.link(topBanner.data.href) && (
							<div className="mt-1.5 block md:hidden">
								<PrismicNextLink
									field={topBanner.data.href}
									aria-label={topBanner.data.button_text || 'top banner button'}
									target="_blank"
									rel="noreferrer"
									className="flex-cc block w-max rounded-full bg-white px-4 py-2 text-xs font-medium uppercase text-theme-green transition-colors hover:bg-gray-100 md:px-5 md:py-2.5 md:text-sm"
								>
									{topBanner.data.button_text}
								</PrismicNextLink>
							</div>
						)}
					</div>
				</div>
				{isFilled.link(topBanner.data.href) && (
					<div className="md:flex-bc relative hidden">
						<PrismicNextLink
							field={topBanner.data.href}
							aria-label={topBanner.data.button_text || 'top banner button'}
							target="_blank"
							rel="noreferrer"
							className="flex-cc block rounded-full bg-white px-4 py-2 text-xs font-medium uppercase text-theme-green transition-colors hover:bg-gray-100 md:px-5 md:py-2.5 md:text-sm"
						>
							{topBanner.data.button_text}
						</PrismicNextLink>
					</div>
				)}
			</div>
		</div>
	);
}

export default TopBanner;

'use client';

// Headless ui
import { Dialog, Transition } from '@headlessui/react';
// Prismic
import { Content, isFilled } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
// Next
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
// React
import { Fragment, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';

// Upsell-kit
import upsell, { toCurrency } from '@monorepo/upsell-kit';

// Type
import { LayoutContextType } from '../../@types/prismic.types';
// Icons
import { CartIcon, ProfileIcon } from '../../components/_shared/Icons';
// Logo
import Logo from '../../components/_shared/Logo';
// Component
import CartModal from '../../components/cart/CartModal';
import { useLayout } from '../../contexts';
// hooks
import useResize from '../../lib/hooks/useResize';
import { useScrollDirection } from '../../lib/hooks/useScrollDirection';
// Mobile navigation
import MobileNavigation from './components/MobileNavigation';
import TopBanner from './components/TopBanner';

/**
 * Props for `NavigationBar`.
 */
export type NavigationBarProps = SliceComponentProps<Content.NavigationBarSlice, LayoutContextType>;

/**
 * Component for "NavigationBar" Slices.
 */
const NavigationBar = ({ slice, context }: NavigationBarProps): JSX.Element => {
	// Get navbar prismic data
	const { navbar, topBanner } = context;
	// Auth Status for show the profile menu
	const { authStatus } = upsell.data.useUser();
	// Save the height value of navbar for the animation when the screen is scrolled
	// const [height, setHeight] = useState<number>(0);
	// Check if the cart is open or not
	const [isCartOpen, setIsCartOpen] = useState(false);
	// Pathname for check the current path
	const pathname = usePathname();

	const { topBannerActive } = useLayout();

	const topBannerPaths = ['/', '/menu'];
	// Detect screensize
	// const screenSize = useResize();
	//Use router for navigate to another page
	const router = useRouter();

	// Assign navbar type
	const navbarType = navbar && navbar.type === 'secondary' ? 'secondary' : 'primary';

	// Get the HTML element
	const ref = useRef<HTMLDivElement>(null);
	// Get the scroll direction either up or down
	const scrollDirection = useScrollDirection();

	// UseEffect for save the height of navbar using ref
	// useEffect(() => {
	// 	if (ref.current) {
	// 		const componentHeight = ref.current.offsetHeight;
	// 		setHeight(componentHeight);
	// 	}
	// }, [ref.current]);

	// State to check if the current scroll position is greater than 0
	const isCurrentPosNotNol = true;

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		setIsCurrentPosNotNol(window.scrollY > 0);
	// 	};

	// 	window.addEventListener('scroll', handleScroll);

	// 	// Check initial position
	// 	handleScroll();

	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	};
	// }, []);

	// Apply style based on the scrollDirection
	const styles: React.CSSProperties = {
		// transform:
		// 	screenSize.width > 1024
		// 		? scrollDirection === 'down'
		// 			? `translateY(-${height}px)`
		// 			: 'translateY(0)'
		// 		: 'translateY(0)',
		transition: `${scrollDirection === 'down' ? `transform 0.3s ease-in-out` : 'transform 0.3s ease-out'}, background-color 0.2s ease-in-out`, // Combined transition properties
		backgroundColor:
			navbarType === 'primary' ? (isCurrentPosNotNol ? 'white' : 'transparent') : `white`,
	};

	// To open Cart
	const handleToggleCart = () => {
		if (cartQuantity.all > 0) {
			setIsCartOpen(true);
		} else {
			router.push(`/menu`);
		}
	};

	// Input function to open and close cart to can be globally used
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.openCart = () => setIsCartOpen(true);
			window.closeCart = () => setIsCartOpen(false);
		}
	}, []);

	// Get the cart quantity
	const cartQuantity = upsell.cart.useQuantity();
	// Get the total price quantity
	const total = upsell.cart.usePrice();

	const { sm } = useResize();

	// Check if navbar needed on the current page
	const excludeNavFromPage = /^\/(deals|products|checkout)(\/[a-f0-9]{24})?$/.test(pathname);

	// If not so the navbar will not show
	if (excludeNavFromPage && !sm) {
		return <></>;
	}

	return (
		// Navbar section
		<>
			<nav
				data-slice-type={slice.slice_type}
				data-slice-variation={slice.variation}
				ref={ref}
				style={styles}
				id="navbar-layout"
				className={`absolute left-0 top-0 z-[50] w-full lg:fixed ${navbarType === 'primary' ? `${isCurrentPosNotNol ? 'border-b border-gray-200 bg-white shadow-md' : '!border-none bg-transparent'}` : 'bg-white'} border-b border-gray-200`}
			>
				{topBanner &&
					topBanner.data &&
					topBanner.data.active &&
					topBannerPaths.includes(pathname) && <TopBanner topBanner={topBanner} />}

				{/* Main Content */}
				<div className="flex-cc lg:flex-bc container w-full py-5">
					{/* LEFT MENUS */}
					<div className="lg:flex-sc hidden gap-5">
						{/* Link to another page */}
						{slice.items.map((item, index) => (
							<Link
								key={index}
								href={isFilled.keyText(item.menu_route) ? item.menu_route : '#'}
								className={`py-2 font-medium uppercase ${navbarType === 'primary' ? `hover:text-theme-yellow-text ${isCurrentPosNotNol ? '!text-primary hover:!text-theme-green-text ' : 'text-white'}` : 'text-primary hover:text-theme-green-text'} ${pathname === item.menu_route ? (navbarType === 'primary' ? '!text-theme-yellow-text' : '!text-theme-green-text') : ''}`}
							>
								{item.menu_name}
							</Link>
						))}
					</div>

					{/* LOGO */}
					<Link
						href="/"
						aria-label="home"
						className="lg:absolute lg:left-1/2 lg:-translate-x-1/2"
					>
						<Logo
							className={`h-[26px] w-auto lg:h-[48px] lg:w-auto [&_#way]:fill-theme-green ${navbarType === `primary` ? ` ${isCurrentPosNotNol ? ' lg:[&_#way]:fill-theme-green ' : 'lg:[&_#way]:fill-white'}` : 'lg:[&_#way]:fill-theme-green'}`}
						/>
					</Link>

					{/* RIGHT MENUS */}
					<div className="lg:flex-ec hidden gap-x-3">
						<button
							onClick={handleToggleCart}
							className="flex-cc block rounded-full bg-theme-green px-5 py-3 font-medium uppercase text-white transition-colors hover:bg-theme-green-dark"
						>
							{cartQuantity.all > 0 ? (
								<div className="flex-cc flex flex-row gap-2">
									<CartIcon size={20} />
									<p>
										{`${cartQuantity.all} ${cartQuantity.all > 1 ? 'VÖRUR' : 'VARA'}`}
									</p>
									<div className="flex h-1 w-1 rounded-full bg-gray-50 opacity-70"></div>
									<p className=" opacity-70">{`${toCurrency(total.price.final)}`}</p>
								</div>
							) : (
								` PANTA`
							)}
						</button>

						<Link
							href={authStatus === 'authenticated' ? '/profile' : '/login'}
							aria-label="profile"
							className={`flex-cc group/profile h-[43px] w-[43px] rounded-full transition-colors hover:bg-gray-100 ${navbarType === 'primary' ? 'bg-white' : 'bg-[#9E9E9E]/35'} ${isCurrentPosNotNol ? 'bg-[#9E9E9E]/20' : ''}`}
						>
							<ProfileIcon
								className={`rounded-full bg-[rgba(0,0,0,0)] transition-colors`}
								size={30}
							/>
						</Link>
					</div>
				</div>

				{/* Cart Section */}
				<Transition as={Fragment} show={isCartOpen}>
					<Dialog
						open={isCartOpen}
						onClose={() => setIsCartOpen(false)}
						style={{
							height: 'calc(100vh - env(safe-area-inset-bottom))',
						}}
						className={[
							'fixed inset-0 z-[30] w-screen -lg:bottom-0 -lg:z-[100]',
							topBannerPaths.includes(pathname) && topBannerActive
								? 'top-auto lg:top-[68px]'
								: 'top-auto',
						].join(' ')}
					>
						<Transition.Child
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="transform opacity-0"
							enterTo="transform opacity-100"
							leave="transition ease-in duration-100"
							leaveFrom="transform opacity-100"
							leaveTo="transform opacity-0"
						>
							<Dialog.Panel
								className={`
									flex-ss col absolute bottom-[100px] left-1/2 right-auto z-[1000] max-h-[calc(100vh-120px)] w-[calc(100%-20px)] -translate-x-1/2
									lg:left-auto
									lg:right-40
									lg:top-20
									lg:h-auto
									lg:w-1/3
									lg:translate-x-0
									lg:translate-y-0
									xl:right-5
              					`}
							>
								<div className="w-full transform overflow-y-auto rounded-lg border border-gray-200 bg-white shadow-lg md:mt-2 lg:w-[500px]">
									<CartModal handleClose={() => setIsCartOpen(false)} />
								</div>
							</Dialog.Panel>
						</Transition.Child>

						<Transition.Child
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="transform opacity-0"
							enterTo="transform opacity-100"
							leave="transition ease-in duration-100"
							leaveFrom="transform opacity-100"
							leaveTo="transform opacity-0"
						>
							<button
								onClick={() => setIsCartOpen(false)}
								className="border-theme-borde flex-cc absolute bottom-[30px] left-1/2 z-[1000] block h-11 w-11 -translate-x-1/2 rounded-full border bg-white lg:hidden"
							>
								<FiX className="pointer-events-none" size={24} />
							</button>
						</Transition.Child>
					</Dialog>
				</Transition>
			</nav>

			{/* Mobile Navigation */}
			<MobileNavigation cartQuantity={cartQuantity.all} handleToggleCart={handleToggleCart} />

			<div
				className={[
					'full fixed inset-0 bg-black lg:z-20 -lg:z-[80]',
					isCartOpen ? 'bg-opacity-50' : 'pointer-events-none bg-opacity-0',
				].join(' ')}
			></div>
		</>
	);
};

export default NavigationBar;
